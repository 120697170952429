import {defineAsyncComponent, AsyncComponentLoader} from "vue";
import LoadingComponent from "@/components/generic/loading-component.vue";

// load project components asynchronously
// we need to set delay to 0 or first load in devextreme will be not visible
export const loaderOptions = (loader: AsyncComponentLoader) => {
    return {
        loader: loader,
        loadingComponent: LoadingComponent,
        delay: 0
    }
};

export const DropDown = defineAsyncComponent(loaderOptions(
    () => import('@/components/generic/drop-down.vue')
));
export const DatePicker = defineAsyncComponent(loaderOptions(
    () => import('@/components/generic/date-picker.vue')
));
export const WorkingTimeRecordDetail = defineAsyncComponent(loaderOptions(
    () => import('@/components/working-time-record-detail.vue')
));
export const StateSvg = defineAsyncComponent(loaderOptions(
    () => import('@/components/generic/state-svg.vue')
));
export const SignaturePad = defineAsyncComponent(loaderOptions(
    () => import('@/components/generic/signature-pad.vue')
));

// popups
export const MissionGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/mission-grid/mission-grid.vue')
));
export const MissionGridContactPerson = defineAsyncComponent(loaderOptions(
    () => import('@/components/mission-grid/mission-grid-contactperson.vue')
));
export const MissionGridMaterial = defineAsyncComponent(loaderOptions(
    () => import('@/components/mission-grid/mission-grid-material.vue')
));
export const MissionGridSMS = defineAsyncComponent(loaderOptions(
    () => import('@/components/mission-grid/mission-grid-sms.vue')
));
export const BillGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/bill-grid.vue')
));
export const ProjectMergeGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/project-merge-grid.vue')
));
export const ChecklistGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/checklist-grid.vue')
));
export const BillProjectDaysGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/bill-project-days-grid.vue')
));
export const BruttoDiffGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/brutto-diff-grid.vue')
));
export const MaterialSetGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/material-set-grid.vue')
));
export const PersonalMergeGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/personal-merge-grid.vue')
));
export const PopupUserCredentials = defineAsyncComponent(loaderOptions(
    () => import('@/components/popup-user-credentials.vue')
));
export const CustomerBillGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/customer/bill-grid.vue')
));
export const CustomerMissionGrid = defineAsyncComponent(loaderOptions(
    () => import('@/components/customer/mission-grid.vue')
));

// layouts
export const defaultLayout = defineAsyncComponent(loaderOptions(
    () => import('@/layouts/side-nav-outer-toolbar.vue')
));
export const simpleLayout = defineAsyncComponent(loaderOptions(
    () => import('@/layouts/single-card.vue')
));
export const fullscreenLayout = defineAsyncComponent(loaderOptions(
    () => import('@/layouts/full-screen.vue')
));

// layout components
export const TheFooter = defineAsyncComponent(loaderOptions(
    () => import('@/layouts/components/the-footer.vue')
));
export const ReloadPrompt = defineAsyncComponent(loaderOptions(
    () => import('@/layouts/components/reload-prompt.vue')
));










