<template>
  <div :class="cssClasses">
    <component
      :is="$route.meta.layout"
      :is-x-small="screen.isXSmall"
      :is-large="screen.isLarge"
    >
      <div class="content">
        <!--keep-alive :max="5"-->
        <!-- allow same components with different views by defining a key -->
        <!-- https://stackoverflow.com/q/42603583/156448 -->
        <router-view :key="$route.path" />
        <!--/keep-alive-->
      </div>
      <!--template #footer-->
      <!--the-footer /-->
      <!--/template-->
      <ReloadPrompt />
    </component>
  </div>
  <div id="custom-popups" />
</template>

<script>
import {
    defaultLayout,
    simpleLayout,
    fullscreenLayout,
    ReloadPrompt
    // TheFooter
} from "@/utils/async-imports";
import {sizes, subscribe, unsubscribe} from "./utils/media-query";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

export default {
  name: "App",

  components: {
      //TheFooter,
      defaultLayout,
      simpleLayout,
      fullscreenLayout,
      ReloadPrompt
  },
  data:() => {
    return {
      screen: getScreenSizeInfo()
    };
  },
  computed: {
    cssClasses() {
      return ["app"].concat(this.screen.cssClasses);
    }
  },

  mounted() {
    subscribe(this.screenSizeChanged);
  },

  beforeUnmount() {
    unsubscribe(this.screenSizeChanged);
  },
  methods: {
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    }
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
