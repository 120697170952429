import {handleErrors} from "@/utils/errors";
import CustomStore, {Options as CustomStoreOptions} from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export const fetchBackend = (endpoint: string, init?: RequestInit): Promise<Response> => {

    const url = new URL(`${import.meta.env.VITE_VEC_BACKEND}${endpoint}`);

    // start xdebug session in development mode and with flag in .env.local
    if (import.meta.env.MODE === "development" && import.meta.env.VITE_VEC_DEBUG === "1") {
        url.searchParams.set("XDEBUG_SESSION_START", "start");
    }

    return fetch(url, {
        credentials: "include", // also send cookie for auth
        headers: {
            "X-Devextreme": "1" // devextreme header for seperation in the backend
        },
        ...init
    });
};

let batchInserts = [] as Array<Record<string, unknown>>,
    batchUpdates = [] as Array<Record<string, unknown>>,
    batchRemoves = [] as Array<Record<string, unknown>>,
    batchInsertsDeferred: Promise<Record<string, unknown>> | undefined,
    batchUpdatesDeferred: Promise<Record<string, unknown>> | undefined,
    batchRemovesDeferred: Promise<void> | undefined;

const processBatchInserts = (insert: Record<string, unknown>, endpoint: string) => {
    batchInserts.push(insert);
    if (!batchInsertsDeferred) {
        batchInsertsDeferred = new Promise((resolve, reject) => {
            setTimeout(batchInsertSend, 0, endpoint, resolve, reject);
        });
    }
    return batchInsertsDeferred;
};


const processBatchUpdates = (update: Record<string, unknown>, endpoint: string) => {
    batchUpdates.push(update);
    if (!batchUpdatesDeferred) {
        batchUpdatesDeferred = new Promise((resolve, reject) => {
            setTimeout(batchUpdateSend, 0, endpoint, resolve, reject);
        });
    }
    return batchUpdatesDeferred;
};

const processBatchRemoves = (remove: Record<string, unknown>, endpoint: string) => {
    batchRemoves.push(remove);
    if (!batchRemovesDeferred) {
        batchRemovesDeferred = new Promise((resolve, reject) => {
            setTimeout(batchRemoveSend, 0, endpoint, resolve, reject);
        });
    }
    return batchRemovesDeferred;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batchInsertSend = (endpoint: string, resolve: any) => {
    const inserts = batchInserts;
        //deferred = batchInsertsDeferred;

    batchInserts = [];
    batchInsertsDeferred = undefined;

    return fetchBackend(endpoint, {
        method: "POST",
        body: JSON.stringify(inserts)
    })
    .then(handleErrors)
    .then((result) => {
        resolve(result);
    });

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batchUpdateSend = (endpoint: string, resolve: any) => {
    const updates = batchUpdates;
        //deferred = batchUpdatesDeferred;

    batchUpdates = [];
    batchUpdatesDeferred = undefined;

    return fetchBackend(endpoint, {
        method: "POST",
        body: JSON.stringify(updates)
    })
    .then(handleErrors)
    .then(() => {
        resolve();
    });

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batchRemoveSend = (endpoint: string, resolve: any) => {
    const removes = batchRemoves;
        //deferred = batchRemovesDeferred;

    batchRemoves = [];
    batchRemovesDeferred = undefined;

    return fetchBackend(endpoint, {
        method: "POST",
        body: JSON.stringify(removes)
    })
    .then(handleErrors)
    .then(() => {
        resolve();
    });
};

type PropertyFunction<T> = () => T;

interface CRUD {
    create?: string | PropertyFunction<string>;
    read: string;
    update?: string | PropertyFunction<string>;
    destroy?: string | PropertyFunction<string>;
}

const isNotEmpty = (value: string | undefined | null): boolean => {
    return value !== undefined && value !== null && value !== "";
};

export const buildQueryString = (params: { [index: string]: any }):string => {
    return Object.keys(params ?? {})
    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
};

export const gridDataSource = (crud: CRUD, storeConfig?: Partial<CustomStoreOptions>):DataSource => new DataSource({
    store: new CustomStore({
        key: "id",
        load: (loadOptions: { [index: string]: any }) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "requireGroupCount",
                "sort",
                "filter",
                "totalSummary",
                "group",
                "groupSummary"
            ].forEach((i: string) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            // add possible userData
            // https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/LoadOptions/#userData
            params += buildQueryString(loadOptions["userData"]);

            //params = params.slice(0, -1);
            return fetchBackend(`${crud.read}${params}`)
            .then(handleErrors)
            .then((result) => {

                // TODO Future: change backend to use data and totalCount instead of rows and total
                result.data = result.rows || [];
                result.totalCount = result.total || 0;
                result.summary = result.summary || [];

                //groupCount: result.groupCount // if required in requireGroupCount
                delete result.rows;
                delete result.total;
                return result;
            });
        },
        insert: (values) => {
            const endpoint = typeof crud.create === "function" ? crud.create() : crud.create;
            return processBatchInserts(values, endpoint || "");
        },
        update: (key, values) => {
            const endpoint = typeof crud.update === "function" ? crud.update() : crud.update;
            return processBatchUpdates({id: key, ...values}, endpoint || "");
        },
        remove: (key) => {
            const endpoint = typeof crud.destroy === "function" ? crud.destroy() : crud.destroy;
            return processBatchRemoves({id: key}, endpoint || "");
        },
        ...storeConfig
    })
});

const baseStore = ({endpoint, params, key = "rows", config = {}}: { endpoint: string, params?: any, key?: string, config?: any }) => {
    return new CustomStore({
        loadMode: "raw",
        cacheRawData: true,
        load: (loadOptions: { [index: string]: any }) => {
            const queryString = {
                ...params,
                ...loadOptions["userData"]
            }

            return fetchBackend(`${endpoint}${queryString ? "?" + buildQueryString(queryString) : ""}`)
            .then(handleErrors)
            .then((result) => {
                return result[key];
            });
        },
        ...config
    });
};

export const regionStore = baseStore({endpoint: "/index/regions", key: "regions"});
export const firmStore = baseStore({endpoint: "/index/firms", key: "firms"});
export const gkkStore = baseStore({endpoint: "/gkk/index?noExport=1"});
export const personalComboStore = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: true},
    config: {key: "id"}
});
export const groupLeaderComboStore = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: false, onlygroupleader: true}
});
export const personalComboStoreProjectLeader = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: false, onlywithlogin: true}
});
export const personalComboStoreForGkk = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: false}
});
export const personalComboStoreForWorkingTime = baseStore({
    endpoint: "/arbeitszeitaufzeichnung/personalcombo",
    config: {
        cacheRawData: false // reload data on every search
    }
});
export const tarifComboStore = baseStore({
    endpoint: "/tarif/index",
    params: {onlyactive: true, noExport: 1},
    config: {key: "id"}
});
export const tarifRestrictedComboStore = baseStore({
    endpoint: "/tarif/comborestricted",
    config: {key: "id"}
});
export const customerComboStore = baseStore({
    endpoint: "/kunde/combo",
    config: {key: "id"}
});
export const materialGroupComboStore = baseStore({
    endpoint: "/materialgruppe/combo",
    config: {
        insert: (values: any) => {
            const endpoint = "/materialgruppe/create"
            return processBatchInserts(values, endpoint || "");
        }
    }
});
export const materialUnitComboStore = baseStore({
    endpoint: "/materialeinheit/combo"
});
